
<table class="table">
    <thead class="my-cart-table-header">
        <tr class="row-head">
            <th scope="col" *ngIf="displayCheckbox"><input type="checkbox" class="form-check-input" [(ngModel)]="selectAllLineItems" (click)="selectAllItems()"></th>
            <th scope="col">{{ 'm.name' | transloco }}</th>
            <th scope="col" class="center-col-content">{{ 'm.sku' | transloco }}</th>
            <th scope="col" class="center-col-content">{{ 'm.quantity' | transloco }}</th>
            <th scope="col" class="right-col-content">{{ 'm.unitPrice' | transloco }}</th>
            <th scope="col" *ngIf="displayCheckbox"></th>
        </tr>
    </thead>
    <tbody>
        <tr class="row-body" *ngFor="let cartItem of cartItems">
            <td *ngIf="displayCheckbox"><input type="checkbox" [(ngModel)]="cartItem.checked" (change)="onSelectCartItems($event, cartItem)"></td>
            <td>{{ getCartItemName(cartItem) }}</td>
            <td class="center-col-content">{{ cartItem.Registration.Sku }}</td>
            <td class="center-col-content">1</td>
            <td class="right-col-content">{{ getSeasonPrice(cartItem) | currency:'USD' }}</td>
            <td *ngIf="displayCheckbox"><a (click)="removeCartItem(cartItem)" (keyup.enter)="removeCartItem(cartItem)" tabindex="0"><i class="fa fa-trash" aria-hidden="true"></i></a></td>
        </tr>
        
        <tr class="row-body" *ngIf="!isMobile">
            <td [colSpan]="displayCheckbox ? colspanWithCheckbox : colspanWithOutCheckbox" class="text-right sub-total-alignment">
                <h3>{{ 'm.subTotal' | transloco }}</h3>
            </td>
            <td class="center-col-content">
                <span class="my-cart-items">{{totalItemsSelected}} {{ 'm.items' | transloco }}</span> 
            </td>
            <td class="right-col-content"><h3>{{subTotal | currency:'USD' }}</h3></td>
            <td *ngIf="displayCheckbox"></td>
        </tr>
        <tr class="row-body" *ngIf="isMobile">
            <td [colSpan]="displayCheckbox ? colspanWithCheckbox : colspanWithOutCheckbox">
                <h3>{{ 'm.subTotal' | transloco }}</h3>
            </td>
            <td class="center-col-content">
                <span class="my-cart-items">{{totalItemsSelected}} {{ 'm.items' | transloco }}</span> 
            </td>
            <td class="right-col-content"><h3>{{subTotal | currency:'USD' }}</h3></td>
            <td *ngIf="displayCheckbox"></td>
        </tr>
        <tr class="row-body" *ngIf="!isMobile && transactionFee > 0">
            <td colSpan="2" class="text-right sub-total-alignment">
                <h3>{{ 'm.transactionFee' | transloco }}</h3>
            </td>
            <td></td>
            <td class="right-col-content"><h3>{{transactionFee | currency:'USD' }}</h3></td>
        </tr>
        <tr class="row-body" *ngIf="isMobile && transactionFee > 0">
            <td colspan="3">
                <h3>{{ 'm.transactionFee' | transloco }}</h3>
            </td>
            <td class="right-col-content"><h3>{{ transactionFee | currency:'USD' }}</h3></td>
        </tr>
        <tr class="row-body" *ngIf="!isMobile && hasAmountDue">
            <td colSpan="2" class="text-right sub-total-alignment">
                <h3>{{ 'm.amountDue' | transloco }}</h3>
            </td>
            <td></td>
            <td class="right-col-content"><h3>{{amountDue | currency:'USD' }}</h3></td>
        </tr>
        <tr class="row-body" *ngIf="isMobile && hasAmountDue">
            <td colspan="3">
                <h3>{{ 'm.amountDue' | transloco }}</h3>
            </td>
            <td class="right-col-content"><h3>{{amountDue | currency:'USD' }}</h3></td>
        </tr>
    </tbody>
</table>
