import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sortByProperty } from '@mt-ng2/common-functions';
import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { IAward } from '@model/interfaces/award';
import { IRegion } from '@model/interfaces/region';
import { ActivatedRoute } from '@angular/router';
import { INotebookLanguage } from '@model/interfaces/notebook-language';
import { IEventVolunteerRole } from '@model/interfaces/event-volunteer-role';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _states: IState[];
    private _countries: ICountry[];
    private _awards: IAward[];
    private _regions: IRegion[];
    private _notebookLanguages: INotebookLanguage[];
    private _eventVolunteerRoles: IEventVolunteerRole[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getAwards(): Observable<IAward[]> {
        if (!this._awards) {
            return this.http.get<IAward[]>('/options/awards').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._awards = answer;
                }),
            );
        } else {
            return of(this._awards);
        }
    }

    getRegions(): Observable<IRegion[]> {
        if (!this._regions) {
            return this.http.get<IRegion[]>('/options/regions').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._regions = answer;
                }),
            );
        } else {
            return of(this._regions);
        }
    }

    getNotebookLanguages(): Observable<INotebookLanguage[]> {
        if (!this._notebookLanguages) {
            return this.http.get<INotebookLanguage[]>('/options/notebook-languages').pipe(
                tap((answer) => {
                    this._notebookLanguages = answer;
                }),
            );
        } else {
            return of(this._notebookLanguages);
        }
    }

    getEventVolunteerRoles(): Observable<IEventVolunteerRole[]> {
        if (!this._eventVolunteerRoles) {
            return this.http.get<IEventVolunteerRole[]>('/options/event-volunteer-roles').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._eventVolunteerRoles = answer;
                }),
            );
        } else {
            return of(this._eventVolunteerRoles);
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
