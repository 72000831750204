import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    IAddressContainer,
    IDynamicFieldType,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { IAddressDynamicControlsParameters, AddressDynamicControls } from '../form-controls/address.form-controls';
import { IAddress } from '../interfaces/address';
import { ValidationErrors, Validators } from '@angular/forms';
import { ICountry } from '@model/interfaces/country';
import { IState } from '@model/interfaces/state';
import { GoogleAutocompleteInputComponent } from '@admin-portal-common/components/google-autocomplete-input/google-autocomplete-input.component';

export interface IAddressDynamicControlsParametersPartial extends IAddressDynamicControlsParameters {
    countries?: ICountry[];
    states?: IState[];
}

export class StandardAddressDynamicControlsPartial extends AddressDynamicControls {
    countries?: ICountry[];
    states?: IState[];

    constructor(address?: IAddress, additionalControls?: IAddressDynamicControlsParametersPartial) {
        super(address, additionalControls);

        this.countries = (additionalControls && additionalControls.countries) || undefined;
        this.states = (additionalControls && additionalControls.states) || undefined;

        (this.Form.GoogleAutoComplete as DynamicField) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Address',
            name: 'GoogleAutoComplete',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.TypeAhead,
                scale: null,
            }),
            component: GoogleAutocompleteInputComponent,
            value: '',
        });

        // All of these fields were copied from mt address. To be exactly the same at the common address component
        // However, we use state id and not state code.
        (<DynamicField>this.Form.Address1) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 1',
            name: 'Address1',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: [Validators.required, Validators.maxLength(50)],
            validators: { required: true, maxlength: 50 },
            value: address?.Address1?.toString() || '',
        });
        (<DynamicField>this.Form.Address2) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 2',
            name: 'Address2',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: [Validators.maxLength(50)],
            validators: { maxlength: 50 },
            value: address?.Address2?.toString() || '',
        });
        (<DynamicField>this.Form.City) = new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            name: 'City',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: [Validators.required, Validators.maxLength(50)],
            validators: { required: true, maxlength: 50 },
            value: address?.City?.toString() || '',
        });
        (<DynamicField>this.Form.Zip) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            name: 'Zip',
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
            validation: [Validators.required, Validators.maxLength(10)],
            validators: { required: true, maxlength: 10 },
            value: address?.Zip?.toString() || '',
        });
        (<DynamicField>this.Form.StateId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'StateId',
            options: this.states,
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select, inputType: SelectInputTypes.Dropdown }),
            validation: [Validators.required],
            validators: { required: true },
            value: address?.StateId || null,
        });
        (<DynamicField>this.Form.CountryCode) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'CountryCode',
            options: this.countries.map((country: ICountry) => ({ Id: country.CountryCode, Name: country.Name })),
            type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Select, inputType: SelectInputTypes.Dropdown }),
            validators: { required: true, maxlength: 2 },
            validation: [Validators.required, Validators.maxLength(2)],
            value: address?.CountryCode?.toString() || '',
        });
    }
}
