import { IUserAddressBook } from '@model/interfaces/user-address-book';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { DynamicCellUserAddressBooksActionsComponent } from './view-user-address-books-dynamic-cell-actions.component';

export class ViewUserAddressBooksEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (userAddressBook: IUserAddressBook): string {
                        return `${userAddressBook.FirstName} ${userAddressBook.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'FirstName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['Organization'],
                    name: 'Organization',
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Country', 'Name'],
                    name: 'Country',
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Address1'],
                    name: 'Address 1',
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Address2'],
                    name: 'Address 2',
                }),
                new EntityListColumn({
                    accessors: ['Address', 'City'],
                    name: 'City',
                }),
                new EntityListColumn({
                    accessors: ['Address', 'State', 'Name'],
                    name: 'State',
                }),
                new EntityListColumn({
                    accessors: ['Address', 'Zip'],
                    name: 'Zip',
                }),
                new EntityListColumn({
                    component: DynamicCellUserAddressBooksActionsComponent,
                    name: 'Default',
                    sort: {
                        disableSort: true,
                    }
                }),
            ],
        };
        super(listConfig);
    }
}
