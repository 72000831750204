import { Directive, OnInit, Input, ViewContainerRef, Type, ComponentRef } from '@angular/core';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';

@Directive({
    selector: '[mtDynamicCell]',
})
export class EntityListDynamicCellDirective<T> implements OnInit {
    @Input({ required: true }) componentRef!: Type<IEntityListDynamicCellComponentGeneric<T>>;
    @Input({ required: true }) item!: T;
    @Input({ required: true }) entityListComponentMembers!: IEntityListComponentMembers<T>;

    component?: ComponentRef<IEntityListDynamicCellComponentGeneric<T>>;

    constructor(private container: ViewContainerRef) {}

    ngOnInit(): void {
        this.component = this.container.createComponent(this.componentRef);
        this.component.instance.entity = this.item;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        (this.component.instance as IEntityListDynamicCellComponentGeneric<T>).entityListComponentMembers = this.entityListComponentMembers;
    }
}
