import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { IAddress } from '@model/interfaces/address';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { StandardAddressDynamicConfig } from './standard-address-form.dynamic-config';
import { CommonService } from '@common/services/common.service';
import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

export interface IExtendedAddress extends IAddress {
    Latitude: number | null;
    Longitude: number | null;
}

@Component({
    selector: 'app-standard-address-form',
    templateUrl: './standard-address-form.component.html',
})
export class StandardAddressFormComponent implements OnInit {
    @Input() address?: IAddress;
    @Input() canEdit!: boolean;
    @Output() saved = new EventEmitter<IExtendedAddress>();

    isEditing = false;
    isHovered = false;
    form: FormGroup;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: StandardAddressDynamicConfig<IAddress>;
    countries: ICountry[] = [];
    states: IState[] = [];
    cachedLatitude: number | null = null;
    cachedLongitude: number | null = null;

    private _commonService = inject(CommonService);
    private _notificationsService = inject(NotificationsService);

    get isNewAddress(): boolean {
        return this.address?.Id ? false : true;
    }

    ngOnInit(): void {
        forkJoin([this._commonService.getCountries(), this._commonService.getStates()]).subscribe(([countries, states]) => {
            this.countries = countries;
            this.states = states;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new StandardAddressDynamicConfig<IAddress>(this.address, {
            countries: this.countries,
            states: this.states,
        });
        // Set up the form configuration
        if (this.isNewAddress) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config.viewOnly;
            this.formObject = config.formObject;
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config.viewOnly;
            this.formObject = config.formObject;
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formCreated(form: FormGroup): void {
        this.form = form;
        const googleAutocompleteControl = form.get(['Address', 'GoogleAutoComplete']);
        googleAutocompleteControl.valueChanges.subscribe((value) => {
            if (value && value.address_components) {
                this.fillAddressFields(value as google.maps.places.PlaceResult);
                googleAutocompleteControl.reset();
            }
        });
    }

    fillAddressFields(place: google.maps.places.PlaceResult): void {
        const addressComponents = place.address_components;
        const addressForm = this.form.get('Address') as FormGroup;

        let address1 = '';
        let countryCode = '';
        let stateId = 0;
        addressComponents.forEach((component) => {
            const componentType = component.types[0];

            addressForm.get('Address1').setValue('');
            switch (componentType) {
                case 'street_number':
                    address1 = component.long_name;
                    break;
                case 'route':
                    address1 = `${address1} ${component.long_name}`;
                    break;
                case 'locality':
                    addressForm.get('City').setValue(component.long_name);
                    break;
                case 'administrative_area_level_1': {
                    const state = this.states.find((state) => state.Name === component.long_name);
                    if (state) {
                        stateId = state.Id;
                    }
                    break;
                }
                case 'country': {
                    const country = this.countries.find((country) => country.Name === component.long_name);
                    if (country) {
                        countryCode = country.CountryCode;
                    }
                    break;
                }
                case 'postal_code':
                    addressForm.get('Zip').setValue(component.long_name);
                    break;
            }
        });
        if (place.geometry && place.geometry.location) {
            this.cachedLatitude = place.geometry.location.lat();
            this.cachedLongitude = place.geometry.location.lng();
        }
        addressForm.get('Address1').setValue(address1.trim());
        addressForm.get('CountryCode').setValue(countryCode);
        addressForm.get('StateId').setValue(stateId);
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            const formValues = form.value.Address as IAddress;
            const returnAddress: IExtendedAddress = {
                Id: this.isNewAddress ? 0 : this.address.Id,
                Address1: formValues.Address1,
                Address2: formValues.Address2,
                City: formValues.City,
                StateId: formValues.StateId,
                Zip: formValues.Zip,
                CountryCode: formValues.CountryCode,
                Province: formValues.Province,
                Latitude: this.cachedLatitude,
                Longitude: this.cachedLongitude,
            };
            this.saved.emit(returnAddress);
            this.isEditing = false;
            this.cachedLatitude = null;
            this.cachedLongitude = null;
        } else {
            markAllFormFieldsAsTouched(form);
            this._notificationsService.error('Save failed. Please check the form and try again.');
        }
    }
}
