import { Component, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '@environment';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { injectStripe, StripePaymentElementComponent } from 'ngx-stripe';
import { StripeService } from './services/stripe.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICustomStripeDto } from '@model/interfaces/custom/custom-stripe-dto';

@Component({
    selector: 'app-stripe-elements',
    templateUrl: './stripe-elements.component.html',
})
export class StripeElementsComponent implements OnInit {
    @ViewChild(StripePaymentElementComponent)
    paymentElement!: StripePaymentElementComponent;

    private readonly fb = inject(FormBuilder);

    paymentElementForm = this.fb.group({
        name: ['John Doe', [Validators.required.bind(this)]],
        email: ['scarnevale@milesit.com', [Validators.required.bind(this)]],
        address: [''],
        zipcode: [''],
        city: [''],
        amount: [2500, [Validators.required.bind(this), Validators.pattern(/\d+/)]],
    });

    elementsOptions: StripeElementsOptions;
    paymentElementOptions: StripePaymentElementOptions = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: false,
        },
    };

    // Replace with your own public key
    stripe = injectStripe(environment.stripe.publishableKey);
    paying = signal(false);

    private notificationsService = inject(NotificationsService);
    private stripeService = inject(StripeService);

    ngOnInit(): void {
        const data: ICustomStripeDto = {
            UserId: 1,
            PaymentType: 'card',
            AmountDue: 1000,
            Currency: 'usd',
        };
        this.stripeService.getPaymentElementClientSecret(data).subscribe((response) => {
            this.elementsOptions = {
                // locale: navigator.language as StripeElementLocale,
                appearance: {
                    variables: {
                        colorPrimary: '#1B365D',
                    },
                },
                // passing the client secret obtained from the server
                clientSecret: response.ClientSecret,
            };
        });
    }

    pay() {
        if (this.paying() || this.paymentElementForm.invalid) return;
        this.paying.set(true);

        const { name, email, address, zipcode, city } = this.paymentElementForm.getRawValue();
        this.stripe
            .confirmPayment({
                elements: this.paymentElement.elements,
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            name: name,
                            email: email,
                            address: {
                                line1: address,
                                postal_code: zipcode,
                                city: city,
                            },
                        },
                    },
                },
                redirect: 'if_required',
            })
            .subscribe((result) => {
                this.paying.set(false);
                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)
                    this.notificationsService.error(result.error.message);
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'processing') {
                        // Show a success message to your customer
                        this.notificationsService.success('Payment received by Stripe and processing');
                    } else if (result.paymentIntent.status === 'succeeded') {
                        // Show a success message to your customer
                        this.notificationsService.success('Payment received by Stripe and succeeded');
                    }
                }
            });
    }
}
