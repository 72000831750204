import { IOrder } from '@model/interfaces/order';
import { OrderDynamicControls, IOrderDynamicControlsParameters } from '../form-controls/order.form-controls';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

export class OrderDynamicControlsPartial extends OrderDynamicControls {

    constructor(order?: IOrder, additionalParameters?: IOrderDynamicControlsParameters) {
        super(order, additionalParameters);
        
        this.Form.PhoneNumber.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Phone,
            scale: null,
        });

        this.View.PhoneNumber.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Phone,
            scale: null,
        });

        
        (<DynamicField>this.Form.OrderStatusId).type.inputType = SelectInputTypes.Dropdown;
    }
}
