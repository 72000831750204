import { Component, HostListener, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IEventMatch } from '../../../model/core/interfaces/event-match';
import { ITeam } from '../../../model/core/interfaces/team';
import { Observable, Subscription} from 'rxjs';
import { TimerService } from '../services/timer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RaceCountdownComponent } from '@tournament-portal-common/components/race-countdown/race-countdown.component';
import { AuthService } from '@mt-ng2/auth-module';
import { HttpResponse } from '@angular/common/http';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EventMatchService } from '@tournament-portal-common/services/event-match.service';
import { EventService } from '../../../admin-portal/events/services/event.service';
import { IEventField } from '@model/interfaces/event-field';
import { EventMatchStatuses } from '@model/enums/event-match-statuses.enum';
import { TimerProgress, TimerEvent, TimerState } from '../../common/components/timer/timer.component';
import { provideTranslocoScope } from '@jsverse/transloco';
import { SoundService } from '@tournament-portal-common/services/sound.service';
import { environment } from '@environment';

@Component({
    selector: 'app-teamwork-display',
    templateUrl: './teamwork-display.component.html',
    styleUrls: ['./teamwork-display.component.less'],
    providers: [
        provideTranslocoScope(
            {
                scope: 'tournament-portal/teamwork-display',
                alias: 'tp.td',
            },
            {
                scope: 'tournament-portal',
                alias: 'tp',
            }
        ),
    ],
})
export class TeamWorkDisplayComponent implements OnInit, AfterViewInit {
    @ViewChild('raceCountdown') raceCountdown!: RaceCountdownComponent;

    eventId: number;
    entityId: string;
    durationMilliseconds = 0;
    timerSub: Subscription;
    loading = true;
    selectedMatches: IEventMatch[] = null;
    selectedMatch: IEventMatch = null;
    teamOne: ITeam;
    teamTwo: ITeam;
    teamThree: ITeam;
    teamFour: ITeam;
    lessThanTenSeconds = false;
    showRaceCountdown = false;
    selectedFieldId: number;
    currentPage = 1;
    itemsPerPage = 999;
    query = '';
    total: number;
    order = 'EventField.Name';
    orderDirection = 'asc';
    eventFields: IEventField[] = [];
    logoFull = `${environment.assetsPath}logo-full.png`;
    programImagePath: string;
    timerState: TimerState = null;
    timerStates = TimerState;

    get timerIsComplete() {
        return this.durationMilliseconds <= 0 || this.timerState === TimerState.EndedEarly;
    }

    constructor(
        private timerService: TimerService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private eventMatchService: EventMatchService,
        private soundService: SoundService,
        private eventService: EventService,
        private el: ElementRef
    ) { }

    ngOnInit(): void {
        this.eventId = this.authService.currentUser.value.CustomOptions.EventId as number;
        if (!this.eventId) {
            void this.router.navigate(['/tournament/displays/teamwork-display-select']);
            return;
        }

        this.selectedFieldId = this.route.snapshot.queryParams['fieldId'];
        if (!this.selectedFieldId) {
            void this.router.navigate(['/tournament/displays/teamwork-display-select']);
            return;
        }
        this.eventService.getById(this.eventId).subscribe((event) => {
            this.programImagePath = environment.assetsPath + 'images/noimage.png';
            const programId: number = event.Season?.ProgramId ?? 0;
            if (programId > 0) {
                this.programImagePath = this.getProgramImageUrl(programId);
            }
        });

        this.getEventMatches();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setDivHeight();
    }

    setDivHeight() {
        const windowHeight = window.innerHeight;
        if (windowHeight) {
            const headerHeight = windowHeight * 0.15;
            this.el.nativeElement.querySelector('.screen-header').style.height = `${headerHeight}px`;
            const divHeight = window.innerHeight - headerHeight;
            this.el.nativeElement.querySelector('.screen-content').style.height = `${divHeight}px`;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setDivHeight();
        }, 0);
    }

    raceCountdownOnStart() {
        this.selectedMatch.EventMatchStatusId = EventMatchStatuses.Started;
    }

    raceCountdownOnFinish() {
        this.showRaceCountdown = false;
    }

    getEventMatchesCall(): Observable<HttpResponse<IEventMatch[]>> {
        const extraParams: ExtraSearchParams[] = [
            { name: 'EventIds', valueArray: [this.eventId] },
            { name: 'FieldIds', valueArray: [this.selectedFieldId] },
            { name: 'EventMatchStatusIds', valueArray: [EventMatchStatuses.Started, EventMatchStatuses.Scheduled] },
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: extraParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: this.query,
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchParams = new SearchParams(searchEntity);
        return this.eventMatchService.get(searchParams);
    }

    getEventMatches(): void {
        this.loading = true;
        this.getEventMatchesCall().subscribe((answer) => {
            this.loading = false;
            this.selectedMatches = answer.body;
            if (!this.selectedMatches || this.selectedMatches.length === 0) {
                return;
            }
            this.selectedMatch = this.selectedMatches[0];
            this.selectedMatches = this.selectedMatches.filter(match => match.Id !== this.selectedMatch.Id);
            this.entityId = `${this.selectedMatch?.Id}`;
            const durationSeconds = this.selectedMatch.EventDivision?.Event?.Season?.SeasonMatchSetting?.QualificationMatchDuration ?? 90;
            this.durationMilliseconds = durationSeconds * 1000;

            this.setupMatchTeams();
            this.stageSelectedMatch();
        });
    }

    getProgramImageUrl(programId: number): string {
        return `${environment.baseApiUrl}/api/v1/programs/${programId}/pic`;
    }

    stageSelectedMatch(): void {
        this.timerService.connectTimerDisplay(this.entityId);
        this.timerSub = this.timerService.timerChanges(this.entityId).subscribe(timer => {
            if (!timer) {
                this.timerState = null;
                this.loading = false;
                return;
            }

            this.timerState = timer.timerState;
            if (this.timerState !== TimerState.Started) {
                this.showRaceCountdown = false;
            }

            if (this.timerState === TimerState.EndedEarly && this.selectedMatch.EventMatchStatusId !== EventMatchStatuses.Completed) {
                this.setSelectedMatchCompleted();
            }
        });
    }

    setupMatchTeams(): void {
        const [teamOne, teamTwo, teamThree, teamFour] = this.selectedMatch?.EventMatchTeams ?? [];
        this.teamOne = teamOne?.Team;
        this.teamTwo = teamTwo?.Team;
        this.teamThree = teamThree?.Team;
        this.teamFour = teamFour?.Team;
    }

    onProgressUpdate(progress: TimerProgress) {
        if (this.entityId !== progress.entityId)
            return;

        if (progress.percentage >= 100 && !this.showRaceCountdown && this.timerState === TimerState.Started) {
            this.showRaceCountdown = true;
            setTimeout(() => {
                this.raceCountdown?.startCountdown();
            }, 1000);
            return;
        }

        if (progress.remainingMs <= 10000) {
            this.lessThanTenSeconds = true;
        } else {
            this.lessThanTenSeconds = false;
        }

        if (this.timerState === TimerState.EndedEarly && this.selectedMatch.EventMatchStatusId !== EventMatchStatuses.Completed) {
            this.setSelectedMatchCompleted();
        }
    }

    onTimerStopped(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        if (event.timer?.durationMilliseconds <= 0 || event.timer?.timerState === TimerState.EndedEarly)
            this.onTimerComplete(event);
    }

    onTimerReset(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        this.durationMilliseconds = event.timer.durationMilliseconds;
    }

    onTimerEndedEarly(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        this.setSelectedMatchCompleted();
    }

    onTimerComplete(event: TimerEvent) {
        if (this.entityId !== event.entityId)
            return;

        this.setSelectedMatchCompleted();
    }

    private setSelectedMatchCompleted(): void {
        this.loading = true;
        this.durationMilliseconds = 0;
        this.soundService.playSuccess();
        this.selectedMatch.EventMatchStatusId = EventMatchStatuses.Completed;
        this.selectedMatches = this.selectedMatches.filter(match => match.Id !== this.selectedMatch.Id);
        setTimeout(() => {
            this.moveToNextMatch();
            this.loading = false;
        }, 1000);
    }

    private moveToNextMatch(): void {
        if (this.selectedMatches.length === 0) {
            return;
        }

        // Set the selected match to the next match in the array
        this.selectedMatch = this.selectedMatches[0];
        this.entityId = `${this.selectedMatch?.Id}`;
        const durationSeconds = this.selectedMatch.EventDivision?.Event?.Season?.SeasonMatchSetting?.QualificationMatchDuration ?? 90;
        this.durationMilliseconds = durationSeconds * 1000;
        this.lessThanTenSeconds = false;
        this.setupMatchTeams();
        this.stageSelectedMatch();
    }

    determineMatchIsBehindSchedule(): boolean {
        // Get the scheduled start time (which is in UTC/Zulu time)
        const scheduledStartUtc = new Date(this.selectedMatch.StartTime);

        // Get current time in UTC
        const currentUtc = new Date();

        // Calculate difference in minutes
        const timeDifference = currentUtc.getTime() - scheduledStartUtc.getTime();
        const minutesDifference = Math.floor(timeDifference / 60000);

        // If minutesDifference > 0, we're behind schedule
        return minutesDifference > 0;
    }

    // Helper method to display the time in local timezone
    formatScheduledTime(): string {
        const scheduledStartUtc = new Date(this.selectedMatch.StartTime);
        const dateString = scheduledStartUtc.toLocaleDateString('en-US');
        const timeString = scheduledStartUtc.toLocaleTimeString('en-US', {
            timeZone: this.selectedMatch?.EventDivision?.Event?.Timezone,
            hour: 'numeric',
            minute: '2-digit'
        });
        return `${dateString} at ${timeString}`;
    }
}
