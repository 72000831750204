import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IOrder } from '../interfaces/order';
import { IUser } from '../interfaces/user';
import { IAddress } from '../interfaces/address';
import { IOrderStatus } from '../interfaces/order-status';

export interface IOrderDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    billingAddresses?: IAddress[];
    billingAddressLatitudes?: IAddress[];
    billingAddressLongitudes?: IAddress[];
    orderStatuses?: IOrderStatus[];
}

export class OrderDynamicControls {
    formGroup: string;
    users?: IUser[];
    billingAddresses?: IAddress[];
    billingAddressLatitudes?: IAddress[];
    billingAddressLongitudes?: IAddress[];
    orderStatuses?: IOrderStatus[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private order?: IOrder,
        additionalParameters?: IOrderDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'Order';
        this.users = additionalParameters?.users ?? undefined;
        this.billingAddresses = additionalParameters?.billingAddresses ?? undefined;
        this.billingAddressLatitudes = additionalParameters?.billingAddressLatitudes ?? undefined;
        this.billingAddressLongitudes = additionalParameters?.billingAddressLongitudes ?? undefined;
        this.orderStatuses = additionalParameters?.orderStatuses ?? undefined;
        this.Form = {
            OrderNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Number',
                name: 'OrderNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.order?.OrderNumber?.toString() || '',
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.order?.UserId || null,
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.order?.FirstName?.toString() || '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.order?.LastName?.toString() || '',
            }),
            Organization: new DynamicField({
                formGroup: this.formGroup,
                label: 'Organization',
                name: 'Organization',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.order?.Organization?.toString() || '',
            }),
            PhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone Number',
                name: 'PhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 15 },
                validation: [Validators.required, Validators.maxLength(15)],
                value: this.order?.PhoneNumber?.toString() || '',
            }),
            BillingAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address',
                name: 'BillingAddressId',
                options: this.billingAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.order?.BillingAddressId || null,
            }),
            BillingAddressLatitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address Latitude',
                name: 'BillingAddressLatitude',
                options: this.billingAddressLatitudes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.order?.BillingAddressLatitude || 0,
            }),
            BillingAddressLongitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address Longitude',
                name: 'BillingAddressLongitude',
                options: this.billingAddressLongitudes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.order?.BillingAddressLongitude || 0,
            }),
            OrderStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Status',
                name: 'OrderStatusId',
                options: this.orderStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.order?.OrderStatusId || null,
            }),
            Subtotal: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subtotal',
                name: 'Subtotal',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [Validators.required],
                value: this.order?.Subtotal || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: { required: true },
                validation: [Validators.required],
                value: this.order?.DateCreated || null,
            }),
            DateDue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Due',
                name: 'DateDue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: { required: true },
                validation: [Validators.required],
                value: this.order?.DateDue || null,
            }),
            TransactionFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Transaction Fee',
                name: 'TransactionFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.order?.TransactionFee || null,
            }),
            AmountDue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount Due',
                name: 'AmountDue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.order?.AmountDue || null,
            }),
        };
        this.View = {
            OrderNumber: new DynamicLabel({
                label: 'Order Number',
                value: this.order?.OrderNumber?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.order?.UserId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.order?.FirstName?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.order?.LastName?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Organization: new DynamicLabel({
                label: 'Organization',
                value: this.order?.Organization?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhoneNumber: new DynamicLabel({
                label: 'Phone Number',
                value: this.order?.PhoneNumber?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddressId: new DynamicLabel({
                label: 'Billing Address',
                value: getMetaItemValue(this.billingAddresses as unknown as IMetaItem[], this.order?.BillingAddressId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddressLatitude: new DynamicLabel({
                label: 'Billing Address Latitude',
                value: getMetaItemValue(this.billingAddressLatitudes as unknown as IMetaItem[], this.order?.BillingAddressLatitude || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddressLongitude: new DynamicLabel({
                label: 'Billing Address Longitude',
                value: getMetaItemValue(this.billingAddressLongitudes as unknown as IMetaItem[], this.order?.BillingAddressLongitude || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OrderStatusId: new DynamicLabel({
                label: 'Order Status',
                value: getMetaItemValue(this.orderStatuses as unknown as IMetaItem[], this.order?.OrderStatusId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Subtotal: new DynamicLabel({
                label: 'Subtotal',
                value: this.order?.Subtotal || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.order?.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateDue: new DynamicLabel({
                label: 'Date Due',
                value: this.order?.DateDue || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            TransactionFee: new DynamicLabel({
                label: 'Transaction Fee',
                value: this.order?.TransactionFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            AmountDue: new DynamicLabel({
                label: 'Amount Due',
                value: this.order?.AmountDue || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
