import {
    EntityListConfig,
    IEntityListConfig,
    IEntityListColumn,
    IEntityListDeleteColumn,
    IEntityListExportConfig,
    IEntityListSelectColumn,
    EntityListPrintConfig,
    EntityListPreventRowSelectionFunction
} from '@mt-ng2/entity-list-module';

// Define the drag-drop configuration interface
export interface IDragDropConfig {
    enabled: boolean;
    dragHandle?: boolean;
    dropZoneClass?: string;
    dragItemClass?: string;
    onlyDropBetween?: boolean;
}

export interface IExtendedEntityListConfig extends IEntityListConfig {
    dragDrop?: IDragDropConfig;
}

export class ExtendedEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];
    delete?: IEntityListDeleteColumn;
    export?: IEntityListExportConfig;
    select?: IEntityListSelectColumn;
    print?: EntityListPrintConfig;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClass?: string | ((entity: any) => string);
    tableClass?: string;
    preventRowSelection?: EntityListPreventRowSelectionFunction | boolean;
    dragDrop?: IDragDropConfig;

    constructor(config: IExtendedEntityListConfig) {
        super(config);

        this.columns = config.columns;
        this.delete = config.delete;
        this.export = config.export;
        this.select = config.select;
        this.print = config.print;
        this.rowClass = config.rowClass;
        this.tableClass = config.tableClass;
        this.preventRowSelection = config.preventRowSelection;

        if (config.dragDrop) {
            this.dragDrop = config.dragDrop;
        }
    }

    override get hasRowClass(): boolean {
        return super.hasRowClass;
    }

    override get hasExport(): boolean {
        return super.hasExport;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override getRowClass(entity: any): string | undefined {
        return super.getRowClass(entity);
    }

    override get hasTableClass(): boolean {
        return super.hasTableClass;
    }

    override getDefaultSortDirection(): string {
        return super.getDefaultSortDirection();
    }

    override getDefaultSortProperty(): string | undefined {
        return super.getDefaultSortProperty();
    }
}
