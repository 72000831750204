import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';
import { GoogleMapLoaderService } from '@common/services/google-map-loader.service';

import { environment } from '@environment';

@Component({
    selector: 'app-root',
    template: `
        <div [hidden]="appReady === null">
            <div class="app-root-wrapper wrapper">
                <div>
                    <ng-progress></ng-progress>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>
                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed: boolean;
    showNav: boolean;
    showFooter: boolean;
    appReady: boolean = null;
    logoFull = `${environment.assetsPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    constructor(private authService: AuthService, private cdr: ChangeDetectorRef, private googleMapLoaderService: GoogleMapLoaderService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                this.cdr.detectChanges();
            }),
        );
        this.loadGoogleMapsApi();
    }

    loadGoogleMapsApi(): void {
        this.googleMapLoaderService.loadMap()
        .then(() => {
            this.googleMapLoaderService.setStatus(true);
        })
        .catch(() => {
            this.googleMapLoaderService.setStatus(false);
        });
    }




    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
