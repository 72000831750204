import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, IAddressContainer, IDynamicFieldType, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IAddressDynamicControlsParameters, AddressDynamicControls } from '../form-controls/address.form-controls';
import { IAddress } from '../interfaces/address';
import { ValidationErrors, Validators } from '@angular/forms';
import { ICountry } from '@model/interfaces/country';
import { IState } from '@model/interfaces/state';
import { GoogleAutocompleteInputComponent } from '@admin-portal-common/components/google-autocomplete-input/google-autocomplete-input.component';

export interface IAddressDynamicControlsParametersPartial extends IAddressDynamicControlsParameters {
    countries?: ICountry[];
    states?: IState[];
}

export function customErrorMessageHandler(key: string, errors: ValidationErrors, dynamicField: DynamicField): string {
    if (key === 'required' || key === 'noZeroRequired') {
        return `${dynamicField.label} field is required, please fill it out to continue`;
    } else if (key === 'invalid') {
        if (dynamicField.name === 'DateOfBirth') {
            return 'The value entered is invalid. Registration is only permitted for individuals aged 13 and older. Please update the information to proceed';
        } else if (dynamicField.name === 'Email') {
            return 'This email address is already associated with an account. Please update the information to proceed';
        }
    }
    return '';
}

export class AddressDynamicControlsPartial extends AddressDynamicControls {
    countries?: ICountry[];
    states?: IState[];

    constructor(address?: IAddress, additionalControls?: IAddressDynamicControlsParametersPartial) {
        super(address, additionalControls);

        this.countries = (additionalControls && additionalControls.countries) || undefined;
        this.states = (additionalControls && additionalControls.states) || undefined;

        (this.Form.GoogleAutoComplete as DynamicField) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Address',
            name: 'GoogleAutoComplete',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.TypeAhead,
                scale: null,
            }),
            component: GoogleAutocompleteInputComponent,
            value: '',
            customOptions: { translationKey: 'selectAddress' },
        });

        (this.Form.Address1 as DynamicField).setRequired(true);
        (this.Form.Address1 as DynamicField).errorMessageHandler = customErrorMessageHandler;

        (this.Form.City as DynamicField).setRequired(true);
        (this.Form.City as DynamicField).errorMessageHandler = customErrorMessageHandler;

        (this.Form.Zip as DynamicField).setRequired(true);
        (this.Form.Zip as DynamicField).errorMessageHandler = customErrorMessageHandler;

        (this.Form.County as DynamicField).setRequired(true);
        (this.Form.County as DynamicField).errorMessageHandler = customErrorMessageHandler;
        (this.View.County as DynamicField).value = address?.County || 'N/A';

        if (this.countries) {
            (this.Form.CountryCode as DynamicField).setRequired(true);
            (this.Form.CountryCode.options = this.countries.map((country: ICountry) => ({ Id: country.CountryCode, Name: country.Name }))),
                (this.Form.CountryCode.type = new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.TypeAhead,
                }));
            (this.Form.CountryCode as DynamicField).value = address?.CountryCode?.toString() || 'US';
            (this.Form.CountryCode as DynamicField).errorMessageHandler = customErrorMessageHandler;
            (this.Form.CountryCode as DynamicLabel).label = 'Country';
        }
        if (this.states) {
            (this.Form.StateId as DynamicField) = new DynamicField({
                formGroup: this.formGroup,
                label: 'Select State/Province',
                name: 'StateId',
                options: this.states,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.TypeAhead,
                } as IDynamicFieldType),
                validators: { required: true },
                validation: [Validators.required],
                value: address?.StateId || null,
                errorMessageHandler: customErrorMessageHandler,
            });
            (this.View.StateId as DynamicField).label = 'Select State/Province';
            (this.View.StateId as DynamicField).value = this.states.find((state: IState) => state.Id === address?.StateId)?.Name || 'N/A';
        }
    }
}
