import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class GoogleMapLoaderService {
    private loader: Loader;
    private mapLibraryStatus = new BehaviorSubject<boolean>(false);
    mapLibrary$ = this.mapLibraryStatus.asObservable();

    constructor() {
        this.loader = new Loader({
            apiKey: environment.googleMaps.apiKey,
            version: 'weekly',
            libraries: ['maps', 'marker', 'places']
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadMap(): Promise<any> {
        return this.loader.load();
    }

    setStatus(value: boolean) {
        this.mapLibraryStatus.next(value);
    }

}