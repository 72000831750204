import { Component, inject, OnInit, OnDestroy, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { provideTranslocoScope, TranslocoService } from '@jsverse/transloco';
import { IOrder } from '@model/interfaces/order';
import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IExpandableObject } from '@model/expandable-object';
import { AddressTranslatedDynamicControls } from '@model/translated-form-controls/address.form-controls';
import { OrderTranslatedDynamicControls } from '@model/translated-form-controls/order.form-controls';
import { CommonService } from '@common/services/common.service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IUserAddressBook } from '@model/interfaces/user-address-book';
import { AuthService } from '@mt-ng2/auth-module';
import { HttpResponse } from '@angular/common/http';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { UserAddressBooksService } from '../../../my-cart/services/user-address-book.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-common-billing-address',
    templateUrl: './billing-address-form.component.html',
    styleUrls: ['./billing-address-form.component.less'],
    providers: [
        provideTranslocoScope({
            scope: 'my-cart',
            alias: 'm',
        }),
    ],
    styles: [
        `
            .btn-next {
                float: right;
            }
        `,
    ],
})
export class CommonBillingAddressComponent implements OnInit, OnDestroy {
    @Input() order: IOrder;
    @Input() hasView: boolean;
    @Input() showBillingAddressForm: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() billingAddressFormSubmitted = new EventEmitter<any>();
    @Output() billingAddressLatLongUpdated = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() cancelFormButton = new EventEmitter<any>();

    billingAddressForm: FormGroup;
    orderControls: IExpandableObject;
    addressControls: IExpandableObject;
    viewOnly: DynamicLabel[] = [];
    states: IState[] = [];
    countries: ICountry[] = [];
    subscriptions: Subscription = new Subscription();
    showState = true;
    renderedStates: IState[];
    viewUserAddressBooksModalWrapperApi: IModalWrapperApi;
    modalOptions: IModalOptions = {
        customClass: { popup: 'view-user-address-books-modal' },
    };
    userId: number;
    userAddressBooks: IUserAddressBook[];
    showView = false;
    cachedStateId = 0;

    private _translocoService = inject(TranslocoService);
    private _fb = inject(FormBuilder);
    private _commonService = inject(CommonService);
    private _authService = inject(AuthService);
    private _userAddressBookService = inject(UserAddressBooksService);
    private cdr = inject(ChangeDetectorRef);
    private _notificationsService = inject(NotificationsService);

    ngOnInit(): void {
        this.userId = this._authService.currentUser.getValue().Id;
        forkJoin([this._commonService.getCountries(), this._commonService.getStates(), this.getUserAddressBooksCall()]).subscribe(
            ([countries, states, userAddressBooksAnswer]) => {
                this.countries = countries;
                this.states = states;
                this.userAddressBooks = userAddressBooksAnswer.body;

                this.setConfig();
                this.fillInDefaultAddress();
                this.modalOptions.confirmButtonText = this._translocoService.translate('m.confirmModalText');
                this._translocoService.selectTranslate('confirmModalText', undefined, 'my-cart').subscribe((value) => {
                    this.modalOptions.confirmButtonText = value;
                });
            },
        );
    }

    private addressBookBuildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserIds',
                valueArray: [this.userId],
            }),
        );

        return _extraSearchParams;
    }

    getUserAddressBooksCall(): Observable<HttpResponse<IUserAddressBook[]>> {
        const search = '';
        const _extraSearchParams: ExtraSearchParams[] = this.addressBookBuildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'desc',
            query: search && (search as string).length > 0 ? search : '',
            skip: 0,
            take: 999,
        };

        const searchparams = new SearchParams(searchEntity);
        return this._userAddressBookService.getSearch(searchparams, this.userId);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setConfig(): void {
        this.renderedStates = this.getStates();
        this.getControls();
        const group = this._fb.group({
            Order: this._fb.group({}),
            Address: this._fb.group({}),
            SaveAddressBook: this._fb.group({
                saveAddressBook: false,
            }),
        });
        this.billingAddressForm = group as FormGroup;
    }
    googleAutoCompleteChanged(value) {
        if (value && value.address_components) {
            this.fillAddressFields(value as google.maps.places.PlaceResult);
            const googleAutocompleteControl = this.billingAddressForm.controls.Address.get('GoogleAutoComplete');
            googleAutocompleteControl.reset();
        }
    }

    fillAddressFields(place: google.maps.places.PlaceResult): void {
        const addressComponents = place.address_components;
        const addressForm = this.billingAddressForm.get('Address');

        let address1 = '';
        let countryCode = '';
        let stateId = 0;

        addressComponents.forEach((component) => {
            const componentType = component.types[0];

            addressForm.get('Address1').setValue('');
            switch (componentType) {
                case 'street_number':
                    address1 = component.long_name;
                    break;
                case 'route':
                    address1 = `${address1} ${component.long_name}`;
                    break;
                case 'locality':
                    addressForm.get('City').setValue(component.long_name);
                    break;
                case 'country': {
                    const country = this.countries.find((country) => country.Name === component.long_name);
                    if (country) {
                        countryCode = country.CountryCode;
                    }
                    break;
                }
                case 'administrative_area_level_1': {
                    const state = this.states.find((state) => state.Name === component.long_name);
                    if (state) {
                        stateId = state.Id;
                    }
                    break;
                }
                case 'postal_code':
                    addressForm.get('Zip').setValue(component.long_name);
                    break;
            }
        });
        if (place.geometry && place.geometry.location) {
            this.billingAddressLatLongUpdated.emit({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            });
        }
        this.cachedStateId = stateId;
        addressForm.get('Address1').setValue(address1.trim());
        addressForm.get('CountryCode').setValue(countryCode);
        addressForm.updateValueAndValidity();
    }

    getControls(): void {
        const orderFormControls = new OrderTranslatedDynamicControls(this._translocoService, this.order, null);
        this.subscriptions.add(orderFormControls.translate().subscribe());
        this.orderControls = orderFormControls.Form;

        const addressFormControls = new AddressTranslatedDynamicControls(
            this._translocoService,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.order?.BillingAddress,
            {
                states: this.renderedStates,
                countries: this.countries,
            },
        );
        this.subscriptions.add(addressFormControls.translate().subscribe());
        this.addressControls = addressFormControls.Form;

        if (this.hasView) {
            const viewableOrderControlFields = ['FirstName', 'LastName', 'Organization', 'PhoneNumber'];
            const viewableAddressPhoneControlFields = ['Address1', 'Address2', 'City', 'CountryCode', 'StateId', 'Zip'];
            this.viewOnly = [
                ...viewableOrderControlFields.map((field) => orderFormControls.View[field]),
                ...viewableAddressPhoneControlFields.map((field) => addressFormControls.View[field]),
            ];
            this.showView = true;
        }
    }

    countryCodeChanged(countryCode: string): void {
        const stateControl = this.billingAddressForm.controls.Address.get('StateId');
        let associatedStates = [] as IState[];
        if (countryCode) {
            associatedStates = this.getStates(countryCode);
        } else if (stateControl) {
            stateControl.disable();
        }

        this.renderedStates.splice(0, this.renderedStates.length, ...associatedStates);
        if (stateControl) {
            if (this.cachedStateId) {
                stateControl.patchValue(this.cachedStateId);
                this.cachedStateId = 0;
            } else {
                stateControl.patchValue(null);
            }
            stateControl.updateValueAndValidity();
        }
        this.showState = associatedStates.length > 0;
        this.cdr.detectChanges();
    }

    getStates(countryCode?: string): IState[] {
        if (countryCode) {
            return this.states.filter((state) => state.CountryCode === countryCode);
        } else {
            return this.states.filter((state) => state.CountryCode === 'US');
        }
    }

    showUserAddressBooksModal(): void {
        this.viewUserAddressBooksModalWrapperApi.show();
    }

    fillInDefaultAddress(): void {
        if (this.userAddressBooks.length > 0) {
            const defaultAddress = this.userAddressBooks.find((address) => address.IsDefault);
            if (defaultAddress) {
                setTimeout(() => {
                    this.addressSelectionMade({ entity: defaultAddress });
                }, 300);
            }
        }
    }

    addressSelectionMade($event): void {
        const selectedUserAddressBook = $event.entity as IUserAddressBook;

        const addressToPatch = {
            Address1: selectedUserAddressBook?.Address?.Address1,
            Address2: selectedUserAddressBook?.Address?.Address2,
            City: selectedUserAddressBook?.Address?.City,
            CountryCode: selectedUserAddressBook?.Address?.CountryCode,
            StateId: selectedUserAddressBook?.Address?.StateId,
            Zip: selectedUserAddressBook?.Address?.Zip,
        };
        const orderToPatch = {
            FirstName: selectedUserAddressBook?.FirstName,
            LastName: selectedUserAddressBook?.LastName,
            Organization: selectedUserAddressBook?.Organization,
            PhoneNumber: selectedUserAddressBook?.Phone,
        };

        this.billingAddressForm.get('Address').patchValue(addressToPatch);
        this.billingAddressForm.get('Order').patchValue(orderToPatch);

        this.billingAddressLatLongUpdated.emit({
            latitude: selectedUserAddressBook?.Latitude,
            longitude: selectedUserAddressBook?.Longitude,
        });
        this.viewUserAddressBooksModalWrapperApi.close();
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.billingAddressFormSubmitted.emit(form);
        } else {
            markAllFormFieldsAsTouched(form);
            const message = this._translocoService.translate(`m.saveFailedForm`);
            this._notificationsService.error(message);
        }
    }

    cancelClick(): void {
        this.cancelFormButton.emit();
    }
}
