import { Validators } from '@angular/forms';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { TranslocoScope, TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { translateDynamicControls } from '@mt-ng2/recf-core';
import { OrderDynamicControlsPartial } from '../partials/order.form-controls';
import { OrderDynamicControls, IOrderDynamicControlsParameters } from '../form-controls/order.form-controls';
import { IOrder } from '../interfaces/order';
import { IUser } from '../interfaces/user';
import { IOrderStatus } from '../interfaces/order-status';

export class OrderTranslatedDynamicControls extends OrderDynamicControlsPartial {
    constructor(
        private translocoService: TranslocoService,
        private orderPartial?: IOrder,
        additionalParameters?: IOrderDynamicControlsParameters,
    ) {
        super(orderPartial, additionalParameters);

        // Translated Form Labels
        (<DynamicField>this.Form.BillingAddressId).customOptions = { translationKey: 'billingAddressId' };
        (<DynamicField>this.Form.DateCreated).customOptions = { translationKey: 'dateCreated' };
        (<DynamicField>this.Form.FirstName).customOptions = { translationKey: 'firstName' };
        (<DynamicField>this.Form.LastName).customOptions = { translationKey: 'lastName' };
        (<DynamicField>this.Form.OrderNumber).customOptions = { translationKey: 'orderNumber' };
        (<DynamicField>this.Form.OrderStatusId).customOptions = { translationKey: 'orderStatusId' };
        (<DynamicField>this.Form.PhoneNumber).customOptions = { translationKey: 'phoneNumber' };
        (<DynamicField>this.Form.Subtotal).customOptions = { translationKey: 'subtotal' };
        (<DynamicField>this.Form.UserId).customOptions = { translationKey: 'userId' };
        (<DynamicField>this.Form.Organization).customOptions = { translationKey: 'organization' };

        // Translated View Labels
        (<DynamicLabel>this.View.BillingAddressId).label = 'billingAddressId';
        (<DynamicLabel>this.View.DateCreated).label = 'dateCreated';
        (<DynamicLabel>this.View.FirstName).label = 'firstName';
        (<DynamicLabel>this.View.LastName).label = 'lastName';
        (<DynamicLabel>this.View.OrderNumber).label = 'orderNumber';
        (<DynamicLabel>this.View.OrderStatusId).label = 'orderStatusId';
        (<DynamicLabel>this.View.PhoneNumber).label = 'phoneNumber';
        (<DynamicLabel>this.View.Subtotal).label = 'subtotal';
        (<DynamicLabel>this.View.UserId).label = 'userId';
        (<DynamicLabel>this.View.Organization).label = 'organization';
    }

    translate(scope: TranslocoScope = 'orders/form'): Observable<unknown[]> {
        return translateDynamicControls(this.translocoService, scope, this);
    }
}
