import { Component, inject, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { provideTranslocoScope } from '@jsverse/transloco';
import { IUserAddressBook } from '@model/interfaces/user-address-book';
import { UserAddressBooksService } from '../../../my-cart/services/user-address-book.service';

@Component({
    providers: [
        provideTranslocoScope({
            scope: 'user-address-books',
            alias: 'uab',
        }),
    ],
    template: `
        <button *ngIf="!isDefault" class="btn btn-primary" (click)="setAsDefault($event)">{{ 'uab.setDefault' | transloco }}</button>
        <p *ngIf="isDefault">{{ 'uab.default' | transloco }}</p>
    `,
})
export class DynamicCellUserAddressBooksActionsComponent implements IEntityListDynamicCellComponentGeneric<IUserAddressBook>, OnInit {
    entity: IUserAddressBook;

    isDefault = false;

    private _userAddressBookService = inject(UserAddressBooksService);

    ngOnInit() {
        this.isDefault = this.entity.IsDefault;
    }

    setAsDefault($event) {
        $event.stopPropagation();
        this._userAddressBookService.setDefault(this.entity.UserId, this.entity.Id).subscribe(() => {
            this._userAddressBookService.emitChange(this.entity);
        });

    }
}
