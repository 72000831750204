import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { AuthService, RefreshInterceptor } from '@mt-ng2/auth-module';
import { TournamentAuthService } from '../../tournament-portal/auth-entity/tournament-auth.service';

@Injectable()
export class RECFRefreshInterceptor extends RefreshInterceptor {
    private myAuthService?: AuthService;
    private tournamentAuthService?: TournamentAuthService;

    constructor(private myInjector: Injector) {
        super(myInjector);
    }

    /**
     * looks for requests to refresh endpoint and logs out the user if a refresh fails.
     * also looks for token-expired or x-update-roles headers on non-refresh end points
     * that fail, and if found runs the tokenRefresh and retries these requests.
     * @param request
     * @param next
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.myAuthService) {
            this.myAuthService = this.myInjector.get(AuthService);
        }

        if (!this.tournamentAuthService) {
            this.tournamentAuthService = this.myInjector.get(TournamentAuthService);
        }

        // if this is a refresh request, then hook into it and logout if error
        if (request.url.endsWith('authUsers/refresh') || request.url.endsWith('tournament-auth/refresh-token')) {
            return next.handle(request).pipe(
                catchError((error) => {
                    if (this.myAuthService) {
                        this.myAuthService.logout(true);
                    }
                    return throwError(() => error);
                }),
            );
        }

        // otherwise we catch errors with the header X-Update-Roles OR Expired-Token
        // and attempt to refresh and push response again
        return next.handle(request).pipe(
            catchError((error) => {
                const tokenRefreshNeeded =
                    error instanceof HttpErrorResponse && (error.headers.has('X-Update-Roles') || error.headers.has('token-expired'));
                if (tokenRefreshNeeded) {
                    error.headers.delete('X-Update-Roles');
                    error.headers.delete('token-expired');
                    return this.handleTokenRefresh(next, request);
                }
                return throwError(() => error);
            }),
            finalize(() => {
                if (this.myAuthService) {
                    this.myAuthService.appReady.next(true);
                }
            }),
        );
    }

     /**
     * reissue the next.handle for a request after a successful refreshToken call has been completed
     * @param next
     * @param request
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected handleTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        if (this.myAuthService) {
            // user is logged into tournament portal
            if (this.myAuthService.currentUser.getValue().CustomOptions.EventId) {
                return this.tournamentAuthService.refreshToken().pipe(
                    switchMap((loginToken) => {
                        request = this.addAuthHeader(request, loginToken.token, loginToken.xsrf);
                        return next.handle(request);
                    }),
                    catchError((innerError) => {
                        // refresh request error hook above will logout the user
                        return throwError(() => innerError);
                    }),
                );
            } else {
                return this.myAuthService.refreshToken().pipe(
                    switchMap((loginToken) => {
                        request = this.addAuthHeader(request, loginToken.token, loginToken.xsrf);
                        return next.handle(request);
                    }),
                    catchError((innerError) => {
                        // refresh request error hook above will logout the user
                        return throwError(() => innerError);
                    }),
                );
            }
        }
        return throwError(() => new Error('AuthService not found'));
    }

}
