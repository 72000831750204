import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading',
    template: `
        <div class="loading-container" [ngStyle]="{'min-height': minHeight}">
            <div class="spinner" [ngStyle]="{
                'width': size + 'px',
                'height': size + 'px',
                'border-width': borderWidth + 'px'
            }"></div>
            <div *ngIf="message" class="message" [ngStyle]="{'margin-top': size / 4 + 'px'}">
                {{message}}
            </div>
        </div>
    `,
    styles: [`
        .loading-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .spinner {
            border-radius: 50%;
            border: 3px solid #f3f3f3;
            border-top: 3px solid #3498db;
            animation: spin 1s linear infinite;
        }

        .message {
            color: #666;
            font-size: 14px;
            text-align: center;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `]
})
export class LoadingComponent {
    @Input() size = 40;
    @Input() borderWidth = 3;
    @Input() message?: string;
    @Input() minHeight = '200px';
}