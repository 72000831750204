import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { IColumnSortedEvent, IItemSelectedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { Observable } from 'rxjs';
import { ViewUserAddressBooksEntityListConfig } from './view-user-address-books.entity-list-config';
import { IDynamicSearchFilters } from '@mt-ng2/dynamic-search-filters';
import { IUserAddressBook } from '@model/interfaces/user-address-book';
import { HttpResponse } from '@angular/common/http';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { UserAddressBooksService } from '../../../my-cart/services/user-address-book.service';
import { provideTranslocoScope } from '@jsverse/transloco';

@Component({
    selector: 'app-view-user-address-books',
    templateUrl: './view-user-address-books.component.html',
    providers: [
        provideTranslocoScope({
            scope: 'user-address-books',
            alias: 'uab',
        }),
    ],
})
export class ViewUserAddressBooksComponent implements OnInit {
    @Input() selectedUserId: number;
    @Output() itemSelected = new EventEmitter<IItemSelectedEvent>();
    modalSelection: IUserAddressBook;

    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    entityListConfig: ViewUserAddressBooksEntityListConfig;
    order: string;
    orderDirection: string;
    userAddressBooks: IUserAddressBook[];
    total: number;
    query = '';
    currentPage = 1;
    itemsPerPage = 10;

    _userAddressBookService = inject(UserAddressBooksService);

    ngOnInit(): void {
        this.initEntityListConfig();
        this.getUserAddressBooks();
        this._userAddressBookService.changeEmitted$.subscribe(() => {
            this.getUserAddressBooks();
        });
    }

    getUserAddressBooks(): void {
        this.getUserAddressBooksCall().subscribe((answer: HttpResponse<IUserAddressBook[]>) => {
            this.userAddressBooks = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getUserAddressBooksCall(): Observable<HttpResponse<IUserAddressBook[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this._userAddressBookService.getSearch(searchparams, this.selectedUserId);
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserIds',
                valueArray: [this.selectedUserId],
            }),
        );

        return _extraSearchParams;
    }

    private initEntityListConfig(): void {
        this.entityListConfig = new ViewUserAddressBooksEntityListConfig();
        this.order = 'Id';
        this.orderDirection = 'desc';
    }

    search(): void {
        this.currentPage = 1;
        this.getUserAddressBooks();
    }

    userAddressBookSelected(event: IItemSelectedEvent): void {
        this.itemSelected.emit(event);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUserAddressBooks();
    }
}
