<div *ngIf="hasView && !showBillingAddressForm">
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
</div>

<form *ngIf="showBillingAddressForm && billingAddressForm" class="padded row" [formGroup]="billingAddressForm" (ngSubmit)="formSubmitted(billingAddressForm)">
    <div class="row">
        <div class="col-md-6">
            <a class="view-all-user-address-books-link"
                (click)="showUserAddressBooksModal()"
                (keydown.enter)="showUserAddressBooksModal()"
                tabindex="0">
                {{ "m.addressBook" | transloco }}
            </a>
        </div>
        <br/>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <mt-dynamic-field [field]="orderControls.FirstName" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
        <div class="col-sm-6">
            <mt-dynamic-field [field]="orderControls.LastName" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <mt-dynamic-field [field]="orderControls.Organization" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
        <div class="col-sm-6">
            <mt-dynamic-field [field]="orderControls.PhoneNumber" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <mt-dynamic-field [field]="addressControls.GoogleAutoComplete" [form]="billingAddressForm" (valueChanges)="googleAutoCompleteChanged($event)"></mt-dynamic-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <mt-dynamic-field [field]="addressControls.Address1" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
        <div class="col-sm-6">
            <mt-dynamic-field [field]="addressControls.Address2" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <mt-dynamic-field [field]="addressControls.City" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
        <div class="col-sm-6">
            <mt-dynamic-field [field]="addressControls.CountryCode" [form]="billingAddressForm" (valueChanges)="countryCodeChanged($event)"></mt-dynamic-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6" *ngIf="showState">
            <mt-dynamic-field [field]="addressControls.StateId" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
        <div class="col-sm-6">
            <mt-dynamic-field [field]="addressControls.Zip" [form]="billingAddressForm"></mt-dynamic-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12" formGroupName="SaveAddressBook">
            <input type="checkbox" formControlName="saveAddressBook">
            <label for="saveAddressBook"> {{ 'm.saveToAddressBook' | transloco }}</label>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success btn-next" >
                <span *ngIf="!hasView">{{ 'm.next' | transloco }}</span>
                <span *ngIf="hasView">{{ 'm.save' | transloco }}</span>
            </button>

            <button *ngIf="hasView" type="button" class="btn btn-flat btn-default" (click)="cancelClick()">{{ 'm.cancel' | transloco }}</button>
        </div>
    </div>
</form>

<mt-modal-wrapper (ready)="viewUserAddressBooksModalWrapperApi = $event" [autoShow]="false" [options]="modalOptions">
    <app-view-user-address-books
        *ngIf="userId"
        (itemSelected)="addressSelectionMade($event)"
        [selectedUserId]="userId" />
</mt-modal-wrapper>




