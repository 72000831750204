import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { ICustomStripeDto } from '@model/interfaces/custom/custom-stripe-dto';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { environment } from '@environment';
import { StripeComponentTypes } from '@model/enums/stripe-component-types.enum';

export interface IStripePaymentSession {
    ClientSecret: string;
}

export interface IStripeAccountSession {
    ClientSecret: string;
    AccountId: string;
}

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    constructor(public http: HttpClient) {}

    getPaymentElementClientSecret(data: ICustomStripeDto): Observable<IStripePaymentSession> {
        return this.http.post<IStripePaymentSession>('/stripe/payment-intent', data);
    }

    getAccountStatus(eventCreatedById: number) {
        return this.http.get<boolean>(`/stripe/${eventCreatedById}/account-status`);
    }

    async initializeStripeConnect(eventCreatedById: number, type: string) {
        const fetchClientSecret = async () => {
            let response;
            if (type === StripeComponentTypes.AccountOnboarding) {
                response = await firstValueFrom(this.http.get<IStripeAccountSession>(`/stripe/${eventCreatedById}/account-onboarding`));
            } else {
                response = await firstValueFrom(this.http.get<IStripeAccountSession>(`/stripe/${eventCreatedById}/account-management`));
            }
            return response.ClientSecret;
        };
    
        // eslint-disable-next-line @typescript-eslint/await-thenable
        const instance = await loadConnectAndInitialize({
            publishableKey: environment.stripe.publishableKey,
            fetchClientSecret,
        });
    
        return instance;
    }
}
