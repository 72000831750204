import { IDynamicConfig, IDynamicFormConfig, DynamicConfig, DynamicLabel } from '@mt-ng2/dynamic-form';

import { IAddressDynamicControlsParametersPartial, StandardAddressDynamicControlsPartial } from '@model/partials/standard-address.form-controls';
import { IAddress } from '@model/interfaces/address';

export class StandardAddressDynamicConfig<T extends IAddress> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private address: T,
        additionalParams?: IAddressDynamicControlsParametersPartial,
        private configControls?: string[],
    ) {
        super();
        const dynamicControls = new StandardAddressDynamicControlsPartial(this.address, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['GoogleAutoComplete', 'CountryCode', 'Address1', 'Address2', 'City', 'StateId', 'Zip'];
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
