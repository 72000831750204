
<div class="screen-header">
    <div class="screen-header-item-left">
        <div class="screen-header-item-left-event-name">{{selectedMatch?.EventField?.Name}}</div>
        <div class="screen-header-item-left-match-number">{{selectedMatch?.MatchNumber}}</div>
    </div>
    <div class="screen-header-item-right">
        <img src="{{ logoFull }}" alt="RECF Logo" class="screen-header-item-right-logo">
        <img [src]="programImagePath" class="screen-header-item-right-logo" alt="Profile Image"/>
    </div>
</div>
<div class="screen-content">
    <div *ngIf="!loading">
        <div *ngIf="!timerIsComplete" class="schedule-info-section">
            <div class="schedule-info-label" [ngClass]="{'behind-schedule': determineMatchIsBehindSchedule(), 'on-schedule': !determineMatchIsBehindSchedule()}">
                {{ 'tp.td.Scheduled Start' | transloco }}: {{formatScheduledTime()}}
            </div>
        </div>
        <div class="content-container">
            <div *ngIf="timerIsComplete && selectedMatches.length <= 0" class="complete-container">
                <div class="complete-text">
                    {{ 'tp.td.All Matches Complete' | transloco }}
                </div>
            </div>
            
            <!-- Race Countdown Display -->
            <div *ngIf="showRaceCountdown" class="timer-container">
                <div class="timer-container-content">
                    <app-race-countdown
                        #raceCountdown
                        [lightInterval]="800"
                        beginText="{{ 'tp.BEGIN!' | transloco }}"
                        startButtonText="Start Race"
                        [showStartButton]="false"
                        lightActiveColor="#34C759"
                        [beginTextDuration]="2000"
                        (countdownStarted)="raceCountdownOnStart()"
                        (countdownFinished)="raceCountdownOnFinish()">
                    </app-race-countdown>
                </div>
            </div>

            <!-- Timer Display -->
            <div *ngIf="!loading && !timerIsComplete && !showRaceCountdown" class="timer-container">
                <div [ngClass]="lessThanTenSeconds ? 'timer-text-red' : 'timer-text-white'" class="timer-container-content">
                    <app-timer-countdown
                        [entityId]="entityId"
                        [totalDisplayTime]="durationMilliseconds"
                        (progressUpdate)="onProgressUpdate($event)"
                        (timerComplete)="onTimerComplete($event)"
                        (timerStopped)="onTimerStopped($event)"
                        (timerEndedEarly)="onTimerEndedEarly($event)"
                        (timerReset)="onTimerReset($event)">
                    </app-timer-countdown>
                </div>
            </div>

            <!-- Score rectangles - only shown when timer is 0 -->
            <div *ngIf="!timerIsComplete && !showRaceCountdown && teamOne && teamTwo && !teamThree && !teamFour" class="score-container score-container-two-v-zero">
                <div class="score-box red">
                    <span class="score-text">{{teamOne.Number}} {{teamOne.Letter}}</span>
                </div>
                <div class="score-box red">
                    <span class="score-text">{{teamTwo.Number}} {{teamTwo.Letter}}</span>
                </div>
            </div>

            <div *ngIf="!timerIsComplete && !showRaceCountdown && teamOne && teamTwo && teamThree && teamFour" class="score-container score-container-two-v-two">
                <div class="score-container-row">
                    <div class="score-box red">
                        <span class="score-text">{{teamOne.Number}} {{teamOne.Letter}}</span>
                    </div>
                    <div class="score-box red">
                        <span class="score-text">{{teamTwo.Number}} {{teamTwo.Letter}}</span>
                    </div>
                    
                </div>
                <div class="score-container-row">
                    <div class="score-box blue">
                        <span class="score-text">{{teamThree.Number}} {{teamThree.Letter}}</span>
                    </div>
                    <div class="score-box blue">
                        <span class="score-text">{{teamFour.Number}} {{teamFour.Letter}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="fullscreen-overlay">
        <app-tournament-loading
            [size]="100"
            [borderWidth]="10">
        </app-tournament-loading>
    </div>
</div>
