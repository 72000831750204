import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, LocalizeDatesByName } from '@mt-ng2/base-service';
import { IUserAddressBook } from '@model/interfaces/user-address-book';
import { SearchParams } from '@mt-ng2/common-classes';
import { catchError, map, Observable } from 'rxjs';

export const emptyUserAddressBook: IUserAddressBook = {
    Id: 0,
    AddressId: 0,
    UserId: 0,
    FirstName: '',
    LastName: '',
    Organization: '',
    Phone: '',
    Latitude: 0,
    Longitude: 0,
    IsDefault: false,
};

@Injectable({
    providedIn: 'root',
})
export class UserAddressBooksService extends BaseService<IUserAddressBook> {
    constructor(public http: HttpClient) {
        super('/users', http, null, { entityName: 'User Address Books' });
    }

    getEmpty(): IUserAddressBook {
        return { ...emptyUserAddressBook };
    }

    getSearch(searchparameters: SearchParams, userId: number): Observable<HttpResponse<IUserAddressBook[]>> {
        const params = this.getHttpParams(searchparameters);
        const url = `/api/v1/users/${userId}/address-books/_search`;
        const result = this.http
            .get<IUserAddressBook[]>(url, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
        return this.datesToLocalize?.length
            ? result.pipe(
                  map((resp) => {
                      resp.body?.forEach((x) => {
                          LocalizeDatesByName(x, this.datesToLocalize);
                      });
                      return resp;
                  }),
              )
            : result;
    }

    setDefault(userId: number, addressBookId: number): Observable<IUserAddressBook> {
        const url = `/api/v1/users/${userId}/address-books/${addressBookId}/set-default`;
        return this.http.put<IUserAddressBook>(url, null);
    }

    checkAddressBookValidity(userId: number, addressBook: IUserAddressBook): Observable<void> {
        const url = `/api/v1/users/${userId}/address-books/check-address-book-validity`;
        return this.http.post<void>(url, addressBook);
    }
}
