<h2>{{ "uab.chooseAnAddress" | transloco }}</h2>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search()"></mt-dynamic-search-filters>
</div>

<mt-entity-list
    [entities]="userAddressBooks"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getUserAddressBooks()"
    (itemSelectedEvent)="userAddressBookSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
/>
