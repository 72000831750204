<div
    *ngIf="!isEditing && address?.Id"
    class="miles-editable miles-card padded"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
    (click)="canEdit && edit()"
    (keydown.enter)="canEdit && edit()"
    (keydown.space)="canEdit && edit()"
    [attr.tabindex]="canEdit ? '0' : '-1'"
    [attr.role]="canEdit ? 'button' : 'text'"
    [attr.aria-label]="canEdit ? 'Edit Address' : 'Address editing not available'"
>
    <h4>
        Address
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly" />
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Address</h4>
    <mt-dynamic-form [config]="formObject" (submitted)="formSubmitted($event)" (formCreated)="formCreated($event)">
        <button type="submit" mtDisableClickDuringHttpCalls class="btn btn-flat btn-success">Save</button>
        <button *ngIf="!isNewAddress" type="button" class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </mt-dynamic-form>
</div>
