import { Validators } from '@angular/forms';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { TranslocoScope, TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { translateDynamicControls } from '@mt-ng2/recf-core';

import { AddressDynamicControls, IAddressDynamicControlsParameters } from '../form-controls/address.form-controls';
import { AddressDynamicControlsPartial, IAddressDynamicControlsParametersPartial } from '../partials/address.form-controls';
import { IAddress } from '../interfaces/address';
import { IState } from '../interfaces/state';
import { ICountry } from '../interfaces/country';

export class AddressTranslatedDynamicControls extends AddressDynamicControlsPartial {
    constructor(
        private translocoService: TranslocoService,
        private addressPartial?: IAddress,
        additionalParameters?: IAddressDynamicControlsParametersPartial,
    ) {
        super(addressPartial, additionalParameters);

        // Translated Form Labels
        (<DynamicField>this.Form.Address1).customOptions = { translationKey: 'address1' };
        (<DynamicField>this.Form.Address2).customOptions = { translationKey: 'address2' };
        (<DynamicField>this.Form.City).customOptions = { translationKey: 'city' };
        (<DynamicField>this.Form.CountryCode).customOptions = { translationKey: 'countryCode' };
        (<DynamicField>this.Form.County).customOptions = { translationKey: 'county' };
        (<DynamicField>this.Form.Province).customOptions = { translationKey: 'province' };
        (<DynamicField>this.Form.StateId).customOptions = { translationKey: 'stateId' };
        (<DynamicField>this.Form.Zip).customOptions = { translationKey: 'zip' };

        // Translated View Labels
        (<DynamicLabel>this.View.Address1).label = 'address1';
        (<DynamicLabel>this.View.Address2).label = 'address2';
        (<DynamicLabel>this.View.City).label = 'city';
        (<DynamicLabel>this.View.CountryCode).label = 'countryCode';
        (<DynamicLabel>this.View.County).label = 'county';
        (<DynamicLabel>this.View.Province).label = 'province';
        (<DynamicLabel>this.View.StateId).label = 'stateId';
        (<DynamicLabel>this.View.Zip).label = 'zip';
    }

    translate(scope: TranslocoScope = 'addresses/form'): Observable<unknown[]> {
        return translateDynamicControls(this.translocoService, scope, this);
    }
}
