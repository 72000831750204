import { Component, Input, EventEmitter, Output } from '@angular/core';
import { provideTranslocoScope } from '@jsverse/transloco';
import { ICustomCartItems } from '@model/interfaces/custom/custom-cart-items';

@Component({
    selector: 'app-my-cart-item-list',
    templateUrl: './my-cart-item-list.component.html',
    providers: [
        provideTranslocoScope({
            scope: 'my-cart',
            alias: 'm',
        }),
    ],
    styleUrls: ['./my-cart-item-list.component.less'],
})
export class MyCartItemListComponent {
    @Input() cartItems: ICustomCartItems[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() myCart: any;
    @Input() isMobile: boolean;
    @Input() totalItemsSelected: number;
    @Input() subTotal: number;
    @Input() displayCheckbox: boolean;
    @Input() transactionFee: number;
    @Input() hasAmountDue: boolean;
    @Input() amountDue: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() deleteItem = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() updateItem = new EventEmitter<any>();
    selectAllLineItems = true;
    colspanWithCheckbox = 3;
    colspanWithOutCheckbox = 2;
    colspanWithCheckboxInItems = 3;
    colspanWithOutCheckboxInItems = 4;


    getCartItemName(cartItem: ICustomCartItems): string {
        let cartItemName = '';
        if (cartItem.Registration.TeamRegistration) {
            const seasonName = cartItem?.Registration?.TeamRegistration?.Team?.Season?.Name ?? '';
            const teamNumber = `${cartItem?.Registration?.TeamRegistration?.Team?.Number ?? ''}${cartItem?.Registration?.TeamRegistration?.Team?.Letter ?? ''}`;
            cartItemName = `${seasonName} Registration for Team Number ${teamNumber}`;
        } else if (cartItem.Registration.EventRegistration) {
            const eventName = cartItem.Registration.EventRegistration.Event?.Name ?? '';
            const teamNumber = `${cartItem?.Registration?.EventRegistration?.Team?.Number ?? ''}${cartItem?.Registration?.EventRegistration?.Team?.Letter ?? ''}`;
            cartItemName = `${teamNumber} Registration for ${eventName}`;
        }
        return cartItemName;
    }

    getSeasonPrice(cartItem: ICustomCartItems): string | number {
        return cartItem?.Registration?.RegistrationCost ?? '0.00';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectCartItems(event: any, cartItem: ICustomCartItems): void {
        const isChecked = event.target.checked;
        const unitPrice = cartItem?.Registration?.TeamRegistration?.Team?.Season?.SeasonRegistrationFee?.USDFee ?? 0;
        if (isChecked) {
            this.subTotal += unitPrice;
            this.selectAllLineItems = this.isAllItemsChecked();
        } else {
            this.selectAllLineItems = false;
            this.subTotal -= unitPrice;
        }
        this.updateItem.emit(this.cartItems);
    }

    isAllItemsChecked(): boolean {
        return this.cartItems.every((checkbox) => checkbox.checked);
    }

    selectAllItems(): void {
        const allChecked = this.isAllItemsChecked();
        this.selectAllLineItems = !allChecked;
        this.cartItems.forEach((checkbox) => (checkbox.checked = !allChecked));
        this.updateItem.emit(this.cartItems);
    }

    removeCartItem(cartItem: ICustomCartItems): void {
        this.deleteItem.emit(cartItem);
    }
}
