<div class="row padded">
    <div class="col-md-12">
        <div [formGroup]="paymentElementForm">
            <div class="form-group">
              <input class="form-control" placeholder="Name" formControlName="name" />
            </div>
            <div class="form-group">
              <input class="form-control" placeholder="Email" type="email" formControlName="email" />
            </div>
            <div class="form-group">
              <input class="form-control" placeholder="Address" formControlName="address" />
            </div>
            <div class="form-group">
              <input class="form-control" placeholder="ZIP Code" formControlName="zipcode" />
            </div>
            <div class="form-group">
              <input class="form-control" placeholder="City" formControlName="city" />
            </div>
            <ngx-stripe-elements *ngIf="stripe && elementsOptions" [stripe]="stripe" [elementsOptions]="elementsOptions">
                <ngx-stripe-payment [options]="paymentElementOptions" />
            </ngx-stripe-elements>
            <br/>
            <button type="submit" class="btn btn-primary btn-flat" (click)="pay()">Pay</button>
        </div>
    </div>
</div>
