import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityListModule, EntityListModuleConfigToken, IEntityListModuleConfig } from '@mt-ng2/entity-list-module';
import { KeyboardShortcutModule, KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';
import { ModalModule } from '@mt-ng2/modal-module';
import { NavModule } from '@mt-ng2/nav-module';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { LoadingComponent } from '@admin-portal-common/components/loading/loading.component';
import { CommonFormDocumentsComponent } from './components/common-form-documents/common-form-documents.component';
import { CustomUrlFieldsComponent } from './components/custom-url-fields/custom-url-fields.component';
import { CustomDateTimeInputComponent } from './components/date-time-input/custom-date-time-input.component';
import { ExtendedCommonAddressesComponent } from './components/extended-common-addresses/extended-common-addresses.component';
import { GoogleAutocompleteInputComponent } from './components/google-autocomplete-input/google-autocomplete-input.component';
import { OrganizationTypeAheadInputComponent } from './components/organization-type-ahead-input/organization-type-ahead-input.component';
import { UserListTypeAheadInputComponent } from './components/user-list-type-ahead-input/user-list-type-ahead-input.component';
import { ConvertToTimezonePipe } from '@common/pipes/convert-to-timezone.pipe';
import { FormatToTimezonePipe } from '@common/pipes/format-to-timezone.pipe';
import { AuditLogUserService } from '@common/services/audit-log-user.service';
import { CommonService } from '@common/services/common.service';
import { auditLogModuleConfig } from '@common/configs/audit-log.config';
import { ExtendedEntityListComponent } from './components/extended-entity-list/extended-entity-list.component';
import { TranslocoModule } from '@jsverse/transloco';
import { VisibilityModule } from '@mt-ng2/visibility-module';
import { EntityListDynamicCellDirective } from './components/extended-entity-list/entity-list-dynamic-cell.directive';
import { MyCartItemListComponent } from './components/my-cart-item-list/my-cart-item-list.component';
import { ViewUserAddressBooksComponent } from './components/view-user-address-books/view-user-address-books.component';
import { DynamicCellUserAddressBooksActionsComponent } from './components/view-user-address-books/view-user-address-books-dynamic-cell-actions.component';
import { CommonBillingAddressComponent } from './components/billing-address-form/billing-address-form.component';
import { StandardAddressFormComponent } from './components/standard-address-form/standard-address-form.component';
import { WysiwygModule, WysiwygModuleConfigToken, IWysiwygModuleConfig } from '@mt-ng2/wysiwyg-control';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

const wysiwygConfig: IWysiwygModuleConfig = {
    inlineStyles: true,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */

@NgModule({
    declarations: [
        CommonFormDocumentsComponent,
        CustomUrlFieldsComponent,
        CustomDateTimeInputComponent,
        OrganizationTypeAheadInputComponent,
        ExtendedCommonAddressesComponent,
        StandardAddressFormComponent,
        UserListTypeAheadInputComponent,
        GoogleAutocompleteInputComponent,
        LoadingComponent,
        ExtendedEntityListComponent,
        EntityListDynamicCellDirective,
        MyCartItemListComponent,
        ViewUserAddressBooksComponent,
        DynamicCellUserAddressBooksActionsComponent,
        CommonBillingAddressComponent,
    ],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ModalModule,
        NavModule,
        MtDisableDuringHttpCallsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DynamicSearchFiltersModule,
        CommonFormDocumentsComponent,
        CustomUrlFieldsComponent,
        CustomDateTimeInputComponent,
        OrganizationTypeAheadInputComponent,
        ExtendedCommonAddressesComponent,
        StandardAddressFormComponent,
        UserListTypeAheadInputComponent,
        GoogleAutocompleteInputComponent,
        LoadingComponent,
        ExtendedEntityListComponent,
        MyCartItemListComponent,
        ViewUserAddressBooksComponent,
        DynamicCellUserAddressBooksActionsComponent,
        CommonBillingAddressComponent,
        WysiwygModule,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ModalModule,
        NavModule,
        MtDisableDuringHttpCallsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DynamicSearchFiltersModule,
        EntityComponentsDocumentsModule,
        MtDocumentControlModule,
        DynamicFormModule,
        EntityListModule,
        TypeAheadModule,
        EntityComponentsAddressesModule,
        TranslocoModule,
        VisibilityModule,
        WysiwygModule,
    ],
})
export class AdminPortalSharedModule {
    static forRoot(): ModuleWithProviders<AdminPortalSharedModule> {
        return {
            ngModule: AdminPortalSharedModule,
            providers: [
                ConvertToTimezonePipe,
                FormatToTimezonePipe,
                KeyboardShortcutService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
                { provide: WysiwygModuleConfigToken, useValue: wysiwygConfig },
            ],
        };
    }
}
